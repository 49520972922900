<script lang="ts">
  import NextButton from './NextButton.svelte';
  import TitleBar from './TitleBar.svelte';
  import Logo from './Logo.svelte';
  import { currentDate, currentMonth, month, year } from '../stores/appData';
  import { isMonthOpen } from '../stores/dropDowns';
  import { setNewDate, toggleOpen } from '../util';

  export let getOffSet;
  export let reset = () => null;

  $: nextMonth = $currentMonth + 1;
  $: previousMonth = $currentMonth - 1;

  $: monthAndYear = `${$month} ${$year}`;

  const setMonth = event => {
    const newMonth = event.detail.value;
    isMonthOpen.set(false);
    setNewDate($year, newMonth);
  };

  const setYear = event => {
    console.log(event);
  };
</script>

<div class="logo">
  <Logo colored on:click={() => currentDate.set(new Date())} />
</div>
<div class="current-month">
  <NextButton
    aria-label="Go to previous month"
    direction="previous"
    on:click={() => {
      reset();
      setNewDate($year, previousMonth);
      setTimeout(getOffSet, 1);
    }}
  />
  <TitleBar year={$year} month={$month} {monthAndYear} />
  <NextButton
    aria-label="Go to next month"
    on:click={() => {
      reset();
      setNewDate($year, nextMonth);
      setTimeout(getOffSet, 1);
    }}
  />
</div>
<div class="mobileMenu" on:click={toggleOpen}>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#000000"
    ><path d="M0 0h24v24H0V0z" fill="none" /><path
      d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"
    /></svg
  >
</div>

<style lang="scss">
  .current-month {
    grid-column: 1 / -1;
    font-size: 2rem;
    text-align: center;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
      grid-column: 2 / -2;
    }
    @media screen and (max-height: 1000px) and (min-width: 769px) {
      font-size: 1rem;
      grid-column: 2 / -2;
    }
    @media print {
      grid-template-columns: 1fr;
    }
  }

  .logo,
  .mobileMenu {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
      align-self: center;
      justify-self: center;
    }
    @media screen and (max-height: 1000px) and (min-width: 769px) {
      display: block;
      align-self: center;
      justify-self: center;
    }
  }
  .mobileMenu {
    @media screen and (max-height: 1000px) and (min-width: 769px) {
      svg {
        fill: var(--primaryText);
      }
    }
    @media screen and (max-width: 768px) {
      svg {
        fill: var(--primaryText);
      }
    }
  }
</style>
