<div class="centered-full-page"><slot /></div>

<style>
  .centered-full-page {
    height: 100vh;
    max-width: 500px;
    margin: 0 auto;
    display: grid;
    place-items: center;
    align-content: center;
  }
</style>
