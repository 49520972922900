<script lang="ts">
  import Color from 'color';
  export let style = '';
  export let size: 'small' | 'medium' | 'large' = 'large';
  export let width: 'full' | 'responsive' = 'responsive';
  export let backgroundColor: string = 'black';
  export let textColor: string = 'white';
  export let variant: 'solid' | 'outline' = 'solid';
  export let className: string = '';
  export let disabled: boolean = false;
  export let loading: boolean = false;

  let backgroundColorHover = '';
  let disabledColor = '';

  $: {
    const color = Color(backgroundColor);
    const isDark = color.isDark();
    const [, , l] = color.hsl().color;
    backgroundColorHover = isDark ? color.lightness(l + 15) : color.lightness(l - 15);
    disabledColor = isDark ? color.lightness(l + 30) : color.lightness(l - 30);
  }
</script>

<button
  {disabled}
  class={className}
  class:small={size === 'small'}
  class:medium={size === 'medium'}
  class:large={size === 'large'}
  class:responsive={width === 'responsive'}
  class:full={width === 'full'}
  class:solid={variant === 'solid'}
  class:outline={variant === 'outline'}
  style={`
    --backgroundColor: ${backgroundColor}; 
    --backgroundColorHover: ${backgroundColorHover}; 
    --textColor: ${textColor}; 
    --disabled-color: ${disabledColor};
    ${style}
  `}
  on:click
>
  {#if loading}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="20px"
      height="20px"
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
    >
      <path
        opacity="0.2"
        fill="#000"
        d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
      />
      <path
        fill="#000"
        d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 20 20"
          to="360 20 20"
          dur="0.5s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  {:else}
    <slot />
  {/if}
</button>

<style lang="scss">
  button {
    --textColor: black;
    line-height: normal;
    font-weight: normal;
    appearance: auto;
    background: none;
    color: auto;
    align-items: center;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: center;
    position: relative;
    transition: all 0.15s ease-in-out;
    user-select: none;
    vertical-align: middle;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    width: 240px;
    padding: 0 calc(1.5rem - 2px);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-size: 13px;
    color: var(--textColor);
    text-align: center;
    text-decoration: none;
    outline: none;
    box-shadow: 0 0 0 0 transparent;
    &.solid {
      --backgroundColor: trasparnet;
      background-color: var(--backgroundColor);
      border-color: var(--backgroundColor);
    }

    &.outline {
      --backgroundColor: trasparnet;
      background-color: transparent;
      border-color: var(--backgroundColor);
    }

    &.small {
      height: 25px;
      padding: 0 10px;
    }
    &.medium {
      height: 48px;
    }
    &.large {
      height: 56px;
    }
    &.responsive {
      width: auto;
    }
    &.full {
      width: 100%;
    }
    &:not(:disabled):hover {
      --backgroundColorHover: trasparent;
      background-color: var(--backgroundColorHover) !important;
      border-color: var(--backgroundColorHover) !important;
    }
    &:disabled {
      --disabled-color: unset;
      background-color: var(--disabled-color);
      border-color: var(--disabled-color);
      cursor: not-allowed;
    }
  }
</style>
