import { __assign, __awaiter, __generator } from "tslib";
import { collection, addDoc, doc, updateDoc, serverTimestamp, deleteDoc, } from 'firebase/firestore';
import { db } from './firebase';
import { currentDate } from './stores/appData';
import { isMonthOpen, isSideBarOpen, isYearOpen } from './stores/dropDowns';
export var holidays = [
    { year: 'every', month: 'January', day: 1, event: "New Year's Day", short: '🎉' },
    { year: 'every', month: 'February', day: 14, event: "Valentine's Day", short: '♥️' },
    { year: 'every', month: 'March', day: 17, event: "St. Patrick's Day", short: '🍀' },
    { year: 'every', month: 'June', day: 19, event: 'Juneteenth' },
    { year: 'every', month: 'July', day: 4, event: 'Independence Day', short: '🇺🇸' },
    { year: 'every', month: 'October', day: 31, event: 'Halloween', short: '🎃' },
    { year: 'every', month: 'November', day: 11, event: 'Veterans Day', short: '🇺🇸' },
    { year: 'every', month: 'December', day: 24, event: 'Christmas Eve', short: '🎅' },
    { year: 'every', month: 'December', day: 25, event: 'Christmas Day', short: '🎄' },
    { year: 'every', month: 'December', day: 31, event: 'New Years Eve', short: '🎆' },
    {
        year: 2022,
        month: 'January',
        day: 17,
        event: 'MLK Jr. Day',
        short: 'MLK'
    },
    { year: 2022, month: 'February', day: 13, event: 'Super Bowl Sunday', short: '🏈' },
    { year: 2022, month: 'February', day: 21, event: "President's Day", short: '🇺🇸' },
    { year: 2022, month: 'April', day: 17, event: 'Easter', short: '🐰' },
    { year: 2022, month: 'May', day: 8, event: "Mother's Day", short: '🌸' },
    { year: 2022, month: 'May', day: 30, event: 'Memorial Day', short: '🇺🇸' },
    { year: 2022, month: 'June', day: 19, event: "Father's Day", short: '⚾' },
    { year: 2022, month: 'July', day: 4, event: 'Independence Day', short: '🇺🇸' },
    { year: 2022, month: 'September', day: 5, event: 'Labor Day', short: '🧑‍🏭' },
    { year: 2022, month: 'October', day: 10, event: 'Columbus Day', short: '⛵' },
    {
        year: 2022,
        month: 'October',
        day: 10,
        event: "US Indigenous People's Day",
        short: '🪶'
    },
    { year: 2022, month: 'November', day: 24, event: 'Thanksgiving', short: '🦃' },
    { year: 2022, month: 'December', day: 18, event: 'Hanukkah Begins', short: '🕯️' },
    {
        year: 2023,
        month: 'January',
        day: 16,
        event: 'MLK Jr. Day',
        short: 'MLK'
    },
    { year: 2023, month: 'February', day: 12, event: 'Super Bowl Sunday', short: '🏈' },
    { year: 2023, month: 'February', day: 20, event: "President's Day", short: '🇺🇸' },
    { year: 2023, month: 'April', day: 9, event: 'Easter', short: '🐰' },
    { year: 2023, month: 'May', day: 14, event: "Mother's Day", short: '🌸' },
    { year: 2023, month: 'May', day: 29, event: 'Memorial Day', short: '🇺🇸' },
    { year: 2023, month: 'June', day: 18, event: "Father's Day", short: '⚾' },
    { year: 2023, month: 'September', day: 4, event: 'Labor Day', short: '🧑‍🏭' },
    { year: 2023, month: 'October', day: 9, event: 'Columbus Day', short: '⛵' },
    {
        year: 2023,
        month: 'October',
        day: 9,
        event: "US Indigenous People's Day",
        short: '🪶'
    },
    { year: 2023, month: 'November', day: 23, event: 'Thanksgiving', short: '🦃' },
    { year: 2023, month: 'December', day: 7, event: 'Hanukkah Begins', short: '🕯️' },
    {
        year: 2024,
        month: 'January',
        day: 15,
        event: 'MLK Jr. Day',
        short: 'MLK'
    },
    { year: 2024, month: 'February', day: 11, event: 'Super Bowl Sunday', short: '🏈' },
    { year: 2024, month: 'February', day: 19, event: "President's Day", short: '🇺🇸' },
    { year: 2024, month: 'April', day: 7, event: 'Easter', short: '🐰' },
    { year: 2024, month: 'May', day: 12, event: "Mother's Day", short: '🌸' },
    { year: 2024, month: 'May', day: 27, event: 'Memorial Day', short: '🇺🇸' },
    { year: 2024, month: 'June', day: 16, event: "Father's Day", short: '⚾' },
    { year: 2024, month: 'September', day: 2, event: 'Labor Day', short: '🧑‍🏭' },
    { year: 2024, month: 'October', day: 14, event: 'Columbus Day', short: '⛵' },
    {
        year: 2024,
        month: 'October',
        day: 14,
        event: "US Indigenous People's Day",
        short: '🪶'
    },
    { year: 2024, month: 'November', day: 28, event: 'Thanksgiving', short: '🦃' },
    { year: 2024, month: 'December', day: 22, event: 'Hanukkah Begins', short: '🕯️' },
];
export var daysOfTheWeek = [
    '<p aria-label="Sunday">S<span arial-hidden="true">unday</span></p>',
    '<p aria-label="Monday">M<span arial-hidden="true">onday</span></p>',
    '<p aria-label="Tuesday">T<span arial-hidden="true">ueday</span></p>',
    '<p aria-label="Wednesday">W<span arial-hidden="true">ednesday</span></p>',
    '<p aria-label="Thursday">T<span arial-hidden="true">hurday</span></p>',
    '<p aria-label="Friday">F<span arial-hidden="true">riday</span></p>',
    '<p aria-label="Saturday">S<span arial-hidden="true">aturday</span></p>',
];
export var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export var displayYears = [2021, 2022, 2023, 2024, 2025];
export function toggleOpen() {
    isSideBarOpen.update(function (open) { return !open; });
}
export function addDataToFirestore(data, userId) {
    return __awaiter(this, void 0, void 0, function () {
        var newDocRef, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!data) return [3 /*break*/, 2];
                    newDocRef = collection(db, 'newMenus', userId, data.year.toString());
                    return [4 /*yield*/, addDoc(newDocRef, __assign(__assign({}, data), { timestamp: serverTimestamp() }))];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function updateDataInFirestore(data, userId) {
    return __awaiter(this, void 0, void 0, function () {
        var newDocRef, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    newDocRef = doc(db, 'newMenus', userId, data.year.toString(), data.id);
                    return [4 /*yield*/, updateDoc(newDocRef, __assign(__assign({}, data), { timestamp: serverTimestamp() }))];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.log(error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function deleteItem(userId, year, docId) {
    return __awaiter(this, void 0, void 0, function () {
        var newDocRef;
        return __generator(this, function (_a) {
            try {
                newDocRef = doc(db, 'newMenus', userId, year, docId);
                deleteDoc(newDocRef);
            }
            catch (error) {
                console.log(error);
            }
            return [2 /*return*/];
        });
    });
}
export var closeAll = function () {
    isMonthOpen.set(false);
    isYearOpen.set(false);
    isSideBarOpen.set(false);
};
export var setNewDate = function (newYear, newMonth) {
    if (newYear === void 0) { newYear = 2021; }
    currentDate.set(new Date(newYear, newMonth));
};
export function getHSLArray(hslColor) {
    if (!hslColor)
        return;
    var _a = hslColor
        .replace(/hsl\(([\d, %]*)\)/, '$1')
        .replace(/ /g, '')
        .replace(/%/g, '')
        .split(','), h = _a[0], s = _a[1], l = _a[2];
    var hexColor = hslToHex(h, s, l);
    return hexColor;
}
function hslToHex(h, s, l) {
    l /= 100;
    var a = (s * Math.min(l, 1 - l)) / 100;
    var f = function (n) {
        var k = (n + h / 30) % 12;
        var color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };
    return "#".concat(f(0)).concat(f(8)).concat(f(4));
}
