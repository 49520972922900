import { __assign, __awaiter, __generator } from "tslib";
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged, connectAuthEmulator, } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator, doc } from 'firebase/firestore';
import { loading, user as userStore } from './stores/user';
import { isSideBarOpen } from './stores/dropDowns';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getApp } from 'firebase/app';
import { docData } from 'rxfire/firestore';
// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: 'AIzaSyCmkRpe5aExnWszdd3Kf09UKXalUEBakTg',
    authDomain: 'menu-update-4a4d9.firebaseapp.com',
    projectId: 'menu-update-4a4d9',
    storageBucket: 'menu-update-4a4d9.appspot.com',
    messagingSenderId: '479512735418',
    appId: '1:479512735418:web:10f8c43b56b023927e1c83'
};
// Initialize Firebase
export var app = initializeApp(firebaseConfig);
var provider = new GoogleAuthProvider();
export var auth = getAuth();
if (location.hostname === 'localhost') {
    connectAuthEmulator(auth, 'http://localhost:9099');
}
export var db = getFirestore();
if (location.hostname === 'localhost') {
    connectFirestoreEmulator(db, 'localhost', 8080);
}
onAuthStateChanged(auth, function (fireUser) { return __awaiter(void 0, void 0, void 0, function () {
    var userRef, userDocData;
    return __generator(this, function (_a) {
        if (fireUser) {
            userRef = doc(db, 'users', fireUser.email);
            userDocData = docData(userRef, { idField: 'currentUserEmail' }).subscribe(function (_a) {
                var sharedEmail = _a.sharedEmail, verified = _a.verified, shared = _a.shared, sharedId = _a.sharedId;
                if (sharedEmail && sharedId) {
                    userStore.update(function (userData) { return (__assign(__assign({}, userData), { sharedEmail: sharedEmail, verified: verified, shared: shared, sharedId: sharedId })); });
                }
            });
            userStore.update(function (data) { return (__assign(__assign({}, data), { email: fireUser.email, picture: fireUser.photoURL, name: fireUser.displayName, id: fireUser.uid })); });
            loading.set(false);
        }
        else {
            loading.set(false);
        }
        return [2 /*return*/];
    });
}); });
export var signIn = function () { return signInWithPopup(auth, provider); };
export var doSignOut = function () {
    signOut(auth).then(function () {
        setTimeout(function () {
            isSideBarOpen.set(false);
        }, 200);
    });
};
var functions = getFunctions(getApp());
if (location.hostname === 'localhost') {
    connectFunctionsEmulator(functions, 'localhost', 5001);
}
