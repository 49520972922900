<script lang="ts">
  export let size: 'large' | 'small' = 'large';
</script>

<h1 class:large={size === 'large'} class:small={size === 'small'}><slot /></h1>

<style lang="scss">
  h1 {
    font-family: 'Outfit', sans-serif;
    text-align: center;
    margin: 0;
    font-weight: 200;
    line-height: 1;
    & :global(span) {
      font-weight: 500;
    }
    &.large {
      font-size: 45px;
      color: var(--primaryText);
      letter-spacing: -2px;
    }

    &.small {
      font-size: 30px;
      color: var(--primaryText);
      letter-spacing: -1px;
    }
  }
</style>
