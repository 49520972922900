<div on:click />

<style>
  div {
    position: absolute;
    inset: 0;
    background-color: #00000020;
    z-index: 1;
  }
</style>
