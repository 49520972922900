<script lang="ts">
  export let colored = false;
  export let large = false;
</script>

<svg
  class="logo"
  class:colored
  class:large
  height="24px"
  width="24px"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 36 36"
  on:click
  ><path
    fill="#BE1931"
    d="M18 2C9.716 2 3 6.492 3 14.5c0 .059.052.758.125 1.509C3.391 15.995 18 28 18 28s14.607-12.006 14.871-11.992c.05-.494.129-1.431.129-1.508C33 6.492 26.284 2 18 2z"
  /><path
    fill="#F4900C"
    d="M30.166 11.509c-.333-.038-.649.04-.918.196-.003-.432-.059-.811-.202-1.104l.376-1.128c.262-.786-.162-1.635-.948-1.897-.351-.119-.713-.093-1.032.036-.192-.72-.884-1.206-1.645-1.1-.123.017-.238.052-.348.096-.153-.565-.63-1.012-1.246-1.096-.831-.111-1.578.463-1.689 1.284L22.2 9.102c-.296-.397-.779-.642-1.306-.6-.643.046-1.157.491-1.329 1.075l-.073-.728c-.083-.825-.825-1.427-1.642-1.343-.816.082-1.412.804-1.343 1.619l-.02.01c.049-.531-.183-1.041-.6-1.346l-.417-2.084c-.163-.812-.95-1.341-1.765-1.177-.812.162-1.339.953-1.177 1.765l.718 3.591-.382 1.605-1.454-4.002c-.284-.779-1.145-1.181-1.922-.897-.779.284-1.181 1.144-.898 1.923l1.108 3.047c-.345.236-.594.61-.646 1.058l-.245 2.099-.327-1.963c-.136-.818-.911-1.372-1.726-1.233-.817.136-1.369.909-1.233 1.726l1.55 9.299h23.305c.049-.122 1.116-9.38 1.116-9.38.09-.824-.503-1.565-1.326-1.657z"
  /><path
    fill="#FFCC4D"
    d="M27.486 7.797c-.009-.064-.028-.123-.044-.184-.192-.72-.884-1.206-1.645-1.1-.123.017-.238.052-.348.096-.627.248-1.031.895-.935 1.593l1.956 14.343h3.028L27.486 7.797zm-4.99 2.096c-.021-.297-.131-.567-.297-.79-.296-.397-.779-.642-1.306-.6-.643.046-1.157.491-1.329 1.075-.049.167-.074.344-.061.528l.879 12.312.009.127H23.4l-.002-.027-.902-12.625zm-11.781 1.408c-.374-.043-.728.06-1.017.258-.345.236-.594.61-.646 1.058l-1.159 9.929h3.02l1.118-9.581c.096-.823-.493-1.568-1.316-1.664zm4.756-5.595c-.163-.812-.95-1.341-1.765-1.177-.812.162-1.339.953-1.177 1.765l3.25 16.252h3.059l-3.367-16.84z"
  /><path
    fill="#DD2E44"
    d="M29 17c0 2.762-4.373 5-11 5S7 19.762 7 17c0-1.104-1.896-1-3-1-.316 0-.609-.005-.875.009C3.186 16.644 5 31 5 31c0 2.209 1.791 4 4 4h18c2.209 0 4-1.791 4-4 0 0 1.858-14.864 1.871-14.992-.264-.014-.557-.008-.871-.008-1.104 0-3-.104-3 1z"
  /><circle fill="#BE1931" cx="18" cy="28" r="4" /></svg
>

<style>
  .large {
    height: 120px;
    width: 120px;
  }
  .colored {
    fill: var(--primary);
    aspect-ratio: 1 / 1;
    filter: drop-shadow(0 5px 10px #6a6a6aac);
  }
</style>
