<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { month, year } from '../stores/appData';
  import TextArea from './TextArea.svelte';
  import Astric from './Astric.svelte';

  export let day: number;
  export let html: string;
  export let id: string;
  export let event: string = '';
  export let eventShort: string = '';
  export let selected: boolean = false;
  export let override: boolean = false;
  export let disabled: boolean = false;

  $: date = new Date();
  $: calDate = new Date(`${$month} ${day} ${$year} `);

  const dispatch = createEventDispatcher();

  function deleteValue() {
    if (id) {
      dispatch('deleteValue', {
        id,
        year: $year,
      });
    }
  }
</script>

<div
  class:selected
  class:override
  class:disabled
  class:past={date > calDate}
  class:today={date.toDateString() === calDate.toDateString()}
  class="day-of-the-month calender-item"
  {id}
  on:click
>
  <div class:override class="row">
    <p class="day" class:event>{day}</p>
    {#if event}
      <span class="event-group">
        <p class="event-title" class:override class:eventShort>{event}</p>
        <p aria-hidden="true" class="event-title-short" class:override class:eventShort>
          {eventShort}
        </p>
      </span>
    {/if}
    <button class="clear" on:click={deleteValue}>clear</button>
  </div>
  <TextArea {override} {html} {id} {day} on:updateValue {disabled} />
  {#if html !== undefined}
    <Astric event={!!event} {override} />
  {/if}
  {#if event}
    <Astric hasEvent={!!event} {override} />
  {/if}
</div>

<style lang="scss">
  .row {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1ch;
    align-items: center;
    font-weight: 600;
  }

  .disabled {
    opacity: 0.6;
    pointer-events: none;
    @media screen and (max-width: 768px) {
      opacity: 0;
    }
  }

  .event-group {
    display: flex;
    gap: 0.5ch;
    justify-content: flex-end;
    align-items: center;
  }

  .event-title,
  .event-title-short {
    margin: 0;
    font-size: 0.8rem;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 110px;
  }

  @media screen and (max-width: 1500px) {
    .event-title {
      max-width: 65px;
    }
  }

  .event-title-short:empty {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .row:not(.override) {
      grid-template-columns: 1fr;
      place-items: center;
    }
    .event-group:not(.override) {
      display: none;
    }
    .event-title-short:not(.override) {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    .event-title.eventShort {
      display: none;
    }

    .event-title-short.eventShort {
      display: block;
    }
  }

  button {
    color: var(--altColor);
    font-size: small;
    border: none;
    background: none;
    display: none;
  }

  button:active {
    background: none;
  }

  button:focus {
    outline: 2px solid var(--primary);
    outline-offset: 2px;
    border-radius: 25px;
  }

  .day-of-the-month {
    border-radius: 0.5rem;
    padding: 0.5rem;
    border: 1px solid var(--altColor);
    display: grid;
    gap: 0.2rem;
    grid-template-rows: auto 1fr;
    color: var(--primaryDark);
    background-color: white;
    position: relative;
  }

  .day-of-the-month.override {
    height: 100%;
    padding: var(--gutter);
  }
  @media screen and (max-width: 768px) {
    .day-of-the-month {
      border: 1px solid white;
      gap: 0;
    }

    .day-of-the-month:not(.override).today {
      border-color: var(--primaryDark);
      box-shadow: 0 0 3px #00000099 inset;
    }
    .day-of-the-month:not(.override).today .day {
      /* transform: scale(120%); */
    }

    .day-of-the-month:not(.override).selected {
      background-color: var(--altColor);
      color: var(--altText);
    }
  }

  @media screen and (max-width: 768px) {
    .day-of-the-month:not(.override) {
      grid-template-rows: auto;
      position: relative;
    }
    .day-of-the-month:not(.override):not(.today).past::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--holiday);
      clip-path: polygon(99% 0, 100% 2%, 1% 100%, 0 98%);
    }
  }

  .day-of-the-month:focus-within:not(.override) {
    border-color: var(--primaryDark);
    /* outline: 1px solid var(--primaryDark);
    outline-offset: 3px; */
    background-color: white;
  }

  .day-of-the-month:focus-within:not(.override) {
    box-shadow: 1px 10px 10px -10px #00000099;
    /* transform: scale(1.5); */
    z-index: 1;
  }

  .day-of-the-month:focus-within .clear {
    display: block;
  }

  .day {
    margin: 0;
    font-weight: bold;
  }
  .day.event {
    color: var(--primaryDark);
  }

  @media screen and (min-width: 551px) {
    .calender-item:nth-of-type(7n + 1):not(.override),
    .calender-item:nth-of-type(7n + 2):not(.override) {
      background-color: hsl(0, 0%, 95%);
    }
  }
  @media print {
    .calender-item {
      border-color: gray;
      background: transparent !important;
      border-radius: 3px;
    }
    p,
    .day.event {
      color: black;
      font-size: small;
    }
    p.event-title {
      color: black;
      font-size: 8px;
    }
    .event-title-short {
      display: none;
    }
  }
</style>
