<script lang="ts">
  type Direction = 'next' | 'previous';
  export let direction: Direction = 'next';
  $$restProps;
</script>

<button on:click>
  <svg
    style={`--rotate: ${direction === 'previous' ? '180deg' : '0deg'}`}
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#000000"
    {...$$restProps}
    ><path d="M0 0h24v24H0V0z" fill="none" /><path
      d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"
    /></svg
  >
</button>

<style lang="scss">
  button {
    border: none;
    background-color: var(--primary);
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    margin: 0;
    height: 40px;
    width: 40px;
    align-self: center;
    display: grid;
    place-items: center;
    padding: 0;
    box-shadow: 0 0 5px #000000cc;
    &:hover {
      background-color: var(--primaryDark);
    }

    &:focus-visible {
      outline: 3px solid var(--primaryDark);
      outline-offset: 3px;
    }

    @media screen and (max-height: 1000px) and (min-width: 769px) {
      height: 30px;
      width: 30px;
    }

    @media screen and (max-width: 768px) {
      height: 30px;
      width: 30px;
      & :global(svg) {
        height: 20px;
        width: 20px;
        fill: var(--primaryText);
      }
    }
    @media print {
      display: none;
    }
  }

  svg {
    --rotate: 0deg;
    transform: rotate(var(--rotate));
    fill: var(--primaryText);
  }
</style>
