<script lang="ts">
  import Button from './Button.svelte';
  import DayOfTheMonth from './DayOfTheMonth.svelte';

  export let mobileInput: HTMLDivElement;
  export let html;
  export let id;
  export let day;
  export let scrollToPosistion;
  export let event = '';
</script>

<div class="mobile-data" bind:this={mobileInput}>
  <button class="close-btn" on:click
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
      ><path d="M0 0h24v24H0V0z" fill="none" /><path
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
      /></svg
    ></button
  >
  <DayOfTheMonth
    {html}
    {id}
    {day}
    {event}
    on:updateValue
    on:deleteValue
    on:click|once={() => scrollToPosistion()}
    override
  />
</div>
<div class="done">
  <Button width="full" size="medium" on:click>Done</Button>
</div>

<style>
  .done,
  .mobile-data {
    display: none;
  }

  .close-btn {
    display: grid;
    place-items: center;
    line-height: 32px;
    vertical-align: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 2px solid var(--primary);
    padding: 0;
    background-color: white;
    transform: scale(0.9);
    position: absolute;
    top: -12px;
    right: -12px;
    z-index: 1;
  }

  .close-btn svg {
    fill: var(--primary);
  }

  @media screen and (max-width: 768px) {
    .mobile-data {
      position: relative;
      display: block;
      grid-column: 1 / -1;
      margin-top: 1rem;
    }
    .done {
      display: block;
      grid-column: 1 / -1;
    }
  }
</style>
