<script lang="ts">
  import { onMount } from 'svelte';
  import { isMonthOpen, isSideBarOpen, isYearOpen } from '../stores/dropDowns';
  import UserBar from './UserBar.svelte';
  import DayOfTheMonth from './DayOfTheMonth.svelte';
  import CalendarTitle from './CalendarTitle.svelte';
  import SelectMonth from './SelectMonth.svelte';
  import DaysOfTheWeek from './DaysOfTheWeek.svelte';
  import SelectedDay from './SelectedDay.svelte';
  import Overlay from './Overlay.svelte';
  import {
    addDataToFirestore,
    updateDataInFirestore,
    closeAll,
    holidays,
    deleteItem,
  } from '../util';
  import type { Data } from '../types';
  import ViewItems from './ViewItems.svelte';
  import { currentMonth, month, year, prevMonth } from '../stores/appData';
  import SharingMessage from './SharingMessage.svelte';
  import ShareConfirm from './ShareConfirm.svelte';

  import { collectionData } from 'rxfire/firestore';
  import { startWith } from 'rxjs/operators';
  import { collection, query, where, orderBy, limitToLast } from 'firebase/firestore';
  import { db } from '../firebase';

  export let uid: string;

  $: collectionRef = query(
    collection(db, 'newMenus', uid, $year.toString()),
    where('month', '==', $month),
  );

  $: data = collectionData(collectionRef, { idField: 'id' }).pipe(startWith([]));

  let selectedDay;

  $: firstDay = new Date($year, $currentMonth).getDay();
  $: prevCollectionRef = query(
    collection(db, 'newMenus', uid, $year.toString()),
    where('month', '==', $prevMonth.label),
    orderBy('day', 'asc'),
    limitToLast(firstDay || 1),
  );

  $: data2 = collectionData(prevCollectionRef, { idField: 'id' }).pipe(startWith([]));
  let daysInMonth = [];
  let startDays = [];

  $: thisMonthsHolidays = holidays.filter(
    holiday =>
      holiday.month === $month && (holiday.year === $year || holiday.year === 'every'),
  );

  $: {
    daysInMonth = Array.from({
      length: 32 - new Date($year, $currentMonth, 32).getDate(),
    })
      .map((_, index) => {
        const day = index + 1;
        const [dayHasData] = $data.filter(data => data.day === day);

        return dayHasData || { day, month: $month, year: $year };
      })
      .map(item => {
        const [holiday] = thisMonthsHolidays.filter(holiday => holiday.day === item.day);
        if (holiday) {
          const { event, short } = holiday;
          return { ...item, event, short };
        }
        return item;
      });
  }
  $: {
    const daysInMonth = 32 - new Date($year, $prevMonth.month, 32).getDate();
    startDays = Array.from({
      length: firstDay,
    })
      .map((_, index) => {
        const day = daysInMonth - index;
        const [dayHasData] = $data2.filter(data => data.day === day);

        return dayHasData || { day, month: $prevMonth.label, year: $year };
      })
      .reverse();
  }

  $: totalDays = firstDay + daysInMonth.length;

  function handleKeydown(event) {
    if (event.key === 'Escape') {
      closeAll();
    }
  }

  function saveData(data: Data) {
    addDataToFirestore(data, uid);
  }

  function updateData(data: Data) {
    updateDataInFirestore(data, uid);
  }

  function setUpdatedValue({ detail }) {
    const [t] = $data.filter(data => data.id === detail.id);
    if (t !== undefined) {
      if (t?.html === detail.html) return;
      updateData({ ...detail, month: $month, year: $year });
      return;
    }
    saveData({ ...detail, month: $month, year: $year });
  }

  function setDeletedValue({ detail }) {
    deleteItem(uid, detail.year.toString(), detail.id);
  }

  function selectDay(day: number) {
    selectedDay = daysInMonth[day];
  }

  function resetSelectedDay() {
    selectedDay = undefined;
  }

  let mobileInput: HTMLDivElement;
  let viewItems: HTMLDivElement;
  let mainOffSet: HTMLElement;

  let mainOffSetHeight: number;
  let hasScrolled: boolean = true;

  function scrollToPosistion() {
    // const { top } = mobileInput.getBoundingClientRect();
    // window.scrollTo({ top, left: 0, behavior: 'smooth' });
  }

  function getOffSet() {
    const { height } = mainOffSet.getBoundingClientRect();
    mainOffSetHeight = height;
  }

  function onScroll() {
    const { top } = viewItems.getBoundingClientRect();
    hasScrolled = top < mainOffSetHeight;
  }

  onMount(getOffSet);
</script>

<svelte:window on:keydown={handleKeydown} on:resize={getOffSet} on:scroll={onScroll} />

<UserBar reset={resetSelectedDay} />

<main
  class="calendar"
  class:sixRows={totalDays > 35}
  class:fourRows={totalDays === 28}
  class:selectedDay
  class:hasScrolled
  bind:this={mainOffSet}
>
  <div class="title-group">
    <CalendarTitle reset={resetSelectedDay} {getOffSet} />
  </div>
  <DaysOfTheWeek />
  {#each startDays as { day, html, id, event, short }}
    <DayOfTheMonth {event} eventShort={short || ''} {html} {id} {day} disabled={true} />
  {/each}
  {#each daysInMonth as { day, html, id, event, short }, index}
    <DayOfTheMonth
      {event}
      eventShort={short || ''}
      {html}
      {id}
      {day}
      on:updateValue={setUpdatedValue}
      on:deleteValue={setDeletedValue}
      on:click={() => selectDay(index)}
    />
  {/each}

  {#if selectedDay}
    <SelectedDay
      {mobileInput}
      event={selectedDay?.event}
      id={selectedDay?.id}
      day={selectedDay?.day}
      html={selectedDay?.html}
      {scrollToPosistion}
      on:click={() => (selectedDay = undefined)}
      on:updateValue={setUpdatedValue}
      on:deleteValue={setDeletedValue}
    />
  {/if}
  <SharingMessage location="footer" />
  <SelectMonth reset={resetSelectedDay} />
</main>

{#if !selectedDay}
  <div bind:this={viewItems}>
    <ViewItems data={daysInMonth} offSet={mainOffSetHeight} onClick={selectDay} />
  </div>
  <ShareConfirm location="footer" />
{/if}

{#if $isMonthOpen || $isYearOpen || $isSideBarOpen}
  <Overlay on:click={closeAll} />
{/if}

<style lang="scss">
  .calendar {
    padding: var(--gutter);
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto auto repeat(5, 1fr) auto;
    height: calc(100vh - 43px);
    &.sixRows {
      grid-template-rows: auto auto repeat(6, 1fr) auto;
    }
    &.fourRows {
      grid-template-rows: auto auto repeat(4, 1fr) auto;
    }

    .title-group {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      @media screen and (max-height: 1000px) and (min-width: 769px) {
        grid-template-columns: auto repeat(5, 1fr) auto;
        gap: 1ch;
      }
    }
    @media screen and (max-width: 768px) {
      height: unset;
      min-height: 100vh;
      transition: box-shadow 300ms, background-color 300ms;
      &.hasScrolled {
        box-shadow: 0 0 10px #000000cc;
        background-color: var(--altColor);
      }

      &:not(.selecteDay) {
        grid-template-rows: repeat(7, auto);
        min-height: unset;
        position: fixed;
        top: 0;
        width: 100%;
      }

      &.selectedDay {
        grid-template-rows: repeat(7, auto) 250px 40px 1fr;
        min-height: 100vh;
        &.sixRows {
          grid-template-rows: repeat(8, auto) 250px 40px 1fr;
        }
        &.fourRows {
          grid-template-rows: repeat(6, auto) 250px 40px 1fr;
        }
      }
    }
    @media screen and (max-height: 1000px) and (min-width: 769px) {
      height: 100vh;
      grid-template-rows: auto auto repeat(5, 1fr);
      &.sixRows {
        grid-template-rows: auto auto repeat(6, 1fr);
      }
      &.fourRows {
        grid-template-rows: auto auto repeat(4, 1fr);
      }
    }
    @media print {
      grid-template-rows: auto auto repeat(5, 1fr);
      gap: 2px;
      height: 100%;
      &.sixRows {
        grid-template-rows: auto auto repeat(6, 1fr);
      }
      &.fourRows {
        grid-template-rows: auto auto repeat(4, 1fr);
      }
    }
  }
</style>
