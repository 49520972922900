// suite.js
import { create, test, enforce, only } from 'vest';
import { isEmail } from 'validator';
enforce.extend({ isEmail: isEmail });
var suite = create(function (data, fieldName) {
    if (data === void 0) { data = {}; }
    only(fieldName);
    test('emailAddress', 'Email Address is required', function () {
        enforce(data.emailAddress).isNotBlank();
    });
    test('emailAddress', 'Email Address is not valid', function () {
        enforce(data.emailAddress).isEmail();
    });
});
export default suite;
