<script lang="ts">
  export let animated = false;
</script>

<div class="bg" class:animated />
<div class="bg bg2" class:animated />
<div class="bg bg3" class:animated />

<style>
  @media screen {
    .bg {
      background-image: linear-gradient(
        -60deg,
        var(--primaryLight) 50%,
        var(--altColor) 50%
      );
      bottom: 0;
      left: -50%;
      opacity: 0.5;
      position: fixed;
      right: -50%;
      top: 0;
      z-index: -1;
    }

    .bg:not(.animated) {
      transform: translateX(-15%);
    }
    .bg2:not(.animated) {
      transform: translateX(19%);
    }
    .bg3:not(.animated) {
      transform: translateX(0);
    }

    .bg.animated {
      animation: slide 5s ease-in-out infinite alternate;
    }

    .bg2.animated {
      animation-direction: alternate-reverse;
      animation-duration: 6s;
    }

    .bg3.animated {
      animation-duration: 8s;
    }

    @keyframes slide {
      0% {
        transform: translateX(-25%);
      }
      100% {
        transform: translateX(25%);
      }
    }
  }
</style>
