<script lang="ts">
  import { currentMonth, year as currentYear } from '../stores/appData';
  import type { Data } from '../types';

  export let onClick;

  export let data: Data[] = [];

  export let offSet: number;

  let futuerDates = [];
  let pastDates = [];

  const date = new Date();

  const today = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  $: {
    if (month === $currentMonth && year === $currentYear) {
      futuerDates = [...data.filter(item => item.day >= today && item.html)];
      pastDates = [...data.filter(item => item.day < today && item.html)];
    } else {
      futuerDates = [...data.filter(item => item.day)];
      pastDates = [];
    }
  }
</script>

{#if data.length && offSet}
  <div class="list" style={`--offSet: ${offSet + 10}px;`}>
    <div class="view-items">
      {#each futuerDates as day, index}
        {#if day.html}
          <dl on:click={() => onClick(day.day - 1)}>
            <dt>{day.day}</dt>
            <dd>
              {@html day.html.replace(/\n/g, '<br/>')}
            </dd>
            {#if day.event}
              <p>{day.event}</p>
            {/if}
          </dl>
        {/if}
      {/each}
    </div>
    {#if pastDates.length}
      <hr />
    {/if}
    <div class="view-items past">
      {#each pastDates as day, index}
        {#if day.html}
          <dl class="past" on:click={() => onClick(day.day - 1)}>
            <dt>{day.day}</dt>
            <dd>
              {@html day.html.replace(/\n/g, '<br/>')}
            </dd>
            {#if day.event}
              <p>{day.event}</p>
            {/if}
          </dl>
        {/if}
      {/each}
    </div>
  </div>
{/if}

<style>
  hr {
    background-color: var(--primary);
    width: 100%;
    border: none;
    height: 1px;
  }
  .list {
    display: none;
    margin: 0;
    padding: 0 1rem 1rem;
    padding-top: var(--offSet);
  }
  .view-items {
    display: none;
  }

  dt {
    margin: 0;
    font-size: 1.1rem;
    justify-self: center;
    text-align: center;
    line-height: 30px;
    color: var(--primaryText);
    background-color: var(--primary);
    border-radius: 50%;
    width: 30px;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    .list {
      display: block;
    }
    .view-items {
      display: grid;
      gap: 1ch;
      grid-column: 1 / -1;
      overflow: hidden;
    }

    .view-items.past {
      gap: 0.75ch;
    }

    dl {
      margin: 0;
      list-style: none;
      background-color: #ffffff66;
      padding: 1ch;
      border-radius: 5px;
      display: grid;
      align-items: start;
      grid-template-columns: 25px 1fr;
      gap: 1ch;
    }

    dl p {
      grid-column: 1 / -1;
      margin: 0;
      font-size: x-small;
      text-align: center;
      color: var(--primary);
      font-weight: bold;
    }

    dl.past {
      opacity: 0.75;
      transform: scale(0.9);
      z-index: -1;
    }
    dd {
      margin: 0;
      background-image: linear-gradient(
        0deg,
        #00000033 2.27%,
        transparent 2.27%,
        transparent 50%,
        #00000033 50%,
        #00000033 52.27%,
        transparent 52.27%,
        transparent 100%
      );
      background-size: 44px 44px;
      font-size: 1rem;
      line-height: 22px;
      padding-left: 0.5ch;
    }
  }
</style>
