<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  export let html;
  export let override: boolean = false;
  export let day: number;
  export let id: string;
  export let disabled: boolean = false;

  const dispatch = createEventDispatcher();

  function setUpdatedValue() {
    if (disabled) return;
    if (html === '' || html === undefined) return;
    if (id) {
      dispatch('updateValue', {
        day,
        html,
        id,
      });
      return;
    }
    dispatch('updateValue', {
      day,
      html,
    });
  }
</script>

<textarea
  class:override
  class="textarea"
  bind:value={html}
  on:blur={setUpdatedValue}
  {disabled}
  {id}
/>
{#if html}
  <div class="print-view" aria-hidden="true">
    {@html html.replace(/(\r\n|\r|\n)/g, '<br/>')}
  </div>
{/if}

<style lang="scss">
  .print-view {
    display: none;
    @media print {
      display: block;
      font-size: x-small;
      line-height: 1;
      color: black;
    }
  }
  .textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
      Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

    border: none;
    width: 100%;
    height: auto;
    margin: 0;
    resize: none;
    font-size: 1rem;
    line-height: 22px;
    align-items: center;
    padding-inline: 5px;
    color: black;
    background-color: transparent;
    background-image: linear-gradient(
      0deg,
      #e0e0e0 2.27%,
      transparent 2.27%,
      transparent 50%,
      #e0e0e0 50%,
      #e0e0e0 52.27%,
      transparent 52.27%,
      transparent 100%
    );
    background-size: 44px 44px;
    word-break: break-all;
    outline: none;
    &:not(.override) {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    @media print {
      display: none;
    }
  }
</style>
