<script lang="ts">
  import { onMount } from 'svelte';

  import Background from './components/Background.svelte';
  import { authState } from 'rxfire/auth';
  import { auth } from './firebase';

  import Calendar from './components/Calendar.svelte';
  import Login from './components/Login.svelte';
  import Loading from './components/Loading.svelte';
  import { signIn } from './firebase';
  import { loading, user as userStore } from './stores/user';
  import {
    currentDate,
    currentMonth,
    year,
    month,
    prevMonth,
    color,
  } from './stores/appData';
  import { getHSLArray, months } from './util';

  $: if ($currentDate) currentMonth.set($currentDate.getMonth());
  $: if ($currentDate) month.set(months[$currentMonth]);
  $: if ($currentDate) {
    const calMonth = $currentDate.getMonth() - 1;
    const month = calMonth === -1 ? 11 : calMonth;
    prevMonth.set({
      label: months[month],
      month,
    });
  }
  $: if ($currentDate) year.set($currentDate.getFullYear());

  const body = document.querySelector('body');

  $: if ($currentDate) {
    body.classList.remove(...body.classList);
    body.classList.add($month.toLocaleLowerCase());
    setTimeout(() => {
      let hsl = getComputedStyle(body).getPropertyValue('--altColor');
      $color = getHSLArray(hsl);
    }, 100);
  }

  let user;

  const unsubscribe = authState(auth).subscribe(u => (user = u));

  let calendarId: string;

  $: if ($userStore) {
    if ($userStore.sharedId && $userStore.verified) {
      calendarId = $userStore.sharedId;
    } else {
      calendarId = user.uid;
    }
  }

  onMount(() => {
    currentDate.set(new Date());
  });
</script>

<Background animated={!user} />

<svelte:head>
  <meta name="theme-color" content={$color} />

  {#if user}
    <title>{user.displayName}'s Menu</title>
  {:else}
    <title>Login to Menu Planner</title>
  {/if}
</svelte:head>

{#if $loading}
  <Loading />
{:else if user}
  <Calendar uid={calendarId} />
{:else}
  <Login on:click={signIn} />
{/if}
