<script lang="ts">
  import { getFunctions, httpsCallable } from 'firebase/functions';
  import { fade } from 'svelte/transition';
  import Button from './Button.svelte';
  import Input from './Input.svelte';

  import suite from '../validation/suite';
  let message = '';

  const functions = getFunctions();

  let res = suite.get();

  let isOpen: boolean = false;
  let showError = '';

  let formState = {
    emailAddress: '',
  };

  function toggleOpen() {
    isOpen = !isOpen;
  }

  const handleChange = name => {
    res = suite(formState, name);
  };

  const shareMenu = httpsCallable(functions, 'newShareMenu');

  async function handleSubmit() {
    disabled = true;
    const { data } = await shareMenu({ email: formState.emailAddress });
    formState.emailAddress = '';
    disabled = false;
    if (data === 'already-added') {
      showError = 'You Have Already Shared with Someone';
    } else if (data === 'no user found') {
      showError = 'No User Found Try Again';
    } else {
      isOpen = false;
    }
  }

  $: disabled = !res.isValid();
</script>

{#if !message}
  <div class="sharebox" out:fade={{ duration: 0.1 }}>
    <Button size="small" backgroundColor="transparent" on:click={toggleOpen}
      >Share With Someone</Button
    >
    {#if isOpen}
      <div class="dropdown" transition:fade>
        {#if showError}
          <div class="row">
            <p>{showError}</p>
            <Button
              on:click={() => {
                isOpen = false;
                showError = '';
              }}
              size="medium">Close</Button
            >
          </div>
        {:else}
          <form on:submit|preventDefault={handleSubmit}>
            <div class="row">
              <Input
                bind:value={formState.emailAddress}
                on:input={handleChange}
                messages={res.getErrors('emailAddress')}
              />
              <Button {disabled} size="medium">Share</Button>
            </div>
          </form>
        {/if}
      </div>
    {/if}
  </div>
{:else}
  <p>{message}</p>
{/if}

<style lang="scss">
  .sharebox {
    position: relative;
  }

  .dropdown {
    position: absolute;
    padding: 1ch;
    background-color: var(--altColor);
    z-index: 2;
    transform: translateX(-150px) translateY(10px);
    border-radius: 0 0 5px 5px;
    @media screen and (max-width: 768px) {
      position: static;
      transform: none;
      padding: 0;
      background-color: unset;
      border-radius: unset;
    }
    @media screen and (max-height: 1000px) and (min-width: 769px) {
      position: static;
      transform: none;
      padding: 0;
      background-color: unset;
      border-radius: unset;
    }
  }
  .row {
    display: flex;
    :global(button) {
      border-radius: 0 5px 5px 0;
    }
    p {
      white-space: nowrap;
      margin: 0;
      margin-right: 1ch;
      align-self: center;
      color: var(--altText);
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 0.5ch;
      :global(button) {
        border-radius: 5px;
      }
    }
    @media screen and (max-height: 1000px) and (min-width: 769px) {
      flex-direction: column;
      gap: 0.5ch;
      :global(button) {
        border-radius: 5px;
      }
    }
  }
</style>
