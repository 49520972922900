<script lang="ts">
  export let override;
  export let event = false;
  export let hasEvent = false;
</script>

<div class="astric" class:event class:override class:hasEvent />

<style lang="scss">
  @media screen and (max-width: 768px) {
    .astric:not(.override) {
      --width: 10px;
      --offset: calc(var(--width) / 2 * -1);
      position: absolute;
      background-color: var(--altColor);
      aspect-ratio: 1 / 1;
      width: var(--width);
      border-radius: 50%;
      right: var(--offset);
      top: var(--offset);
      border: 1px solid white;
      z-index: 1;
      &.event {
        --width: 10px;
        --offset: calc(var(--width) / 2 * -1);
        right: calc(var(--offset) + 6px);
        z-index: 2;
      }
      &.hasEvent {
        background-color: var(--holiday);
      }
    }
  }
</style>
