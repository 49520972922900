<script lang="ts">
  import { user } from '../stores/user';
  export let location: 'footer' | 'sidebar';
</script>

{#if $user && $user.sharedEmail && $user.verified}
  <div class={`sharing-message ${location}`}>
    <p><strong>{$user?.sharedEmail}</strong> shared their menu with you</p>
  </div>
{/if}

<style>
  .sharing-message.footer {
    grid-column: span 7;
  }

  .sharing-message p {
    margin: 0;
    text-align: center;
    color: var(--altText);
  }

  .sidebar {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .sidebar {
      display: block;
    }
    .footer {
      display: none;
    }
  }
  @media screen and (max-height: 1000px) and (min-width: 769px) {
    .sidebar {
      display: block;
    }
    .footer {
      display: none;
    }
  }
  @media print {
    .sharing-message {
      display: none;
    }
  }
</style>
