<script lang="ts">
  export let value;
  export let messages: string[] = [];
  export let validityClass: string = '';
</script>

<div>
  <input type="email" bind:value on:input class={validityClass} />
  {#each messages as message}
    <p>{message}</p>
  {/each}
</div>

<style lang="scss">
  div {
    display: grid;
    gap: 1ch;
  }
  input {
    font-size: 1rem;
    border-radius: 5px 0 0 5px;
    border: 2px solid black;
    width: 200px;
    font-family: var(--font-family);
    padding-inline: 1ch;
    outline: none;
    height: 48px;
    width: 250px;
    @media screen and (max-width: 768px) {
      border-radius: 5px;
    }
    &:focus {
      box-shadow: 0 0 3px #000000cc inset;
    }
  }

  p {
    margin: 0;
    color: var(--primaryText);
  }
</style>
