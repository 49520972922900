<script lang="ts">
  import { daysOfTheWeek } from '../util';
</script>

{#each daysOfTheWeek as dayOfTheWeek}
  <div class="day-of-the-week">{@html dayOfTheWeek}</div>
{/each}

<style lang="scss">
  .day-of-the-week {
    font-weight: bold;
    text-align: center;
    border: none;
    background-color: var(--primary);
    color: var(--primaryText);
    padding: 1ch;
    border-radius: 0.5rem;
    @media screen and (max-height: 1000px) and (min-width: 769px) {
      padding: 0.5ch;
      font-size: 0.8rem;
    }
  }

  .day-of-the-week :global(p) {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    .day-of-the-week {
      grid-row: 2;
    }
  }

  @media screen and (max-width: 768px) {
    .day-of-the-week {
      padding: 0.5ch;
    }
    .day-of-the-week :global(p span) {
      display: none;
    }
  }

  @media print {
    .day-of-the-week {
      border-radius: 3px;
      color: black;
      background-color: white;
      border: 1px solid gray;
      font-size: small;
      padding: 3px;
    }
  }
</style>
