<script lang="ts">
  import { onMount } from 'svelte';
  import Color from 'color';
  import Button from './Button.svelte';
  import { year, currentMonth, color as backgroundColor, month } from '../stores/appData';
  import { months, setNewDate, displayYears } from '../util';
  import { isSideBarOpen } from '../stores/dropDowns';

  export let showInSidebar = false;
  export let reset;

  let button: HTMLElement;
  let buttonWidth: number | undefined;
  let open: boolean = false;

  function openYearsMenu() {
    getButtonSize();
    open = !open;
  }

  function getButtonSize() {
    const { width } = button.getBoundingClientRect();
    buttonWidth = width;
  }

  function setNewYear(newYear: number) {
    const monthIndex = months.findIndex(item => item === $month);
    setNewDate(newYear, monthIndex);
    open = false;
  }

  function setNewMonth(newMonth: number) {
    setNewDate($year, newMonth);
    open = false;
  }

  let color1 = 'white';
  let color2 = 'black';

  $: if ($backgroundColor) {
    try {
      const color = Color($backgroundColor);
      const isDark = color.isDark();
      const [, , l] = color.hsl().color;
      color1 = color.hex();
      color2 = isDark ? color.lightness(l + 15).hex() : color.lightness(l - 15).hex();
    } catch (error) {
      console.error(error);
    }
  }
  onMount(() => {
    getButtonSize();
  });
</script>

<svelte:window on:resize={getButtonSize} />

<footer class="show-months" class:showInSidebar>
  {#each months as month, index}
    <div
      class="month"
      class:active={months[$currentMonth] === month}
      on:click={() => {
        setNewMonth(index);
        if (showInSidebar) {
          isSideBarOpen.set(false);
        }
        reset();
      }}
    >
      <span class="long">{month}</span>
      <span class="short">{month.substr(0, 3)}</span>
    </div>
  {/each}
  <div class="year-container">
    <ul class="year-list" class:open style={`--button-width: ${buttonWidth}px;`}>
      {#each displayYears as displayYear, index}
        <li
          style={buttonWidth && `--button-width: ${buttonWidth}px; --delay: ${index}`}
          class:selected={displayYear === $year}
        >
          <Button
            backgroundColor={displayYear === $year ? color2 : color1}
            width={showInSidebar ? 'responsive' : 'full'}
            size="medium"
            style={!showInSidebar && `--textColor: var(--primaryColor)`}
            on:click={() => {
              setNewYear(displayYear);
              if (showInSidebar) {
                isSideBarOpen.set(false);
              }
              reset();
            }}>{displayYear}</Button
          >
        </li>
      {/each}
    </ul>
    <div class="month year" bind:this={button} on:click={openYearsMenu}>
      {$year}
    </div>
  </div>
</footer>

<style lang="scss">
  :root {
    --time: 200ms;
  }
  ul {
    --button-width: 0px;
    width: calc(var(--button-width) + var(--gutter));
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    gap: 0.5ch;
    position: absolute;
    z-index: 1;
    transform: translateY(calc(-100% - 0.5ch));
    overflow: hidden;
    pointer-events: none;
    &.open {
      pointer-events: all;
      li {
        opacity: 1;
        transform: translateX(0);
      }
    }
    li {
      --button-width: 0px;
      --delay: 0;
      opacity: 0;
      width: var(--button-width);
      transform: translateX(calc(100% + var(--gutter)));
      transition: all var(--time) ease-in-out;
      transition-duration: calc(var(--delay) * 50ms);
    }
  }

  .year {
    z-index: 1;
    position: relative;
  }
  .show-months {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(13, 1fr);
    align-items: center;
    grid-column: 1 / -1;
  }

  .show-months.showInSidebar {
    display: none;
  }
  .short {
    display: none;
  }

  .month {
    padding: 1ch;
    border-radius: 0.3rem;
    border: 1px solid var(--primary);
    background-color: var(--primaryDark);
    color: var(--primaryText);
    text-align: center;
    cursor: pointer;
  }
  .month:hover {
    border: 1px solid var(--primaryDark);
    background-color: var(--primary);
    color: var(--primaryText);
  }
  .month.active {
    border-color: var(--primaryDark);
    background-color: var(--primary);
    color: var(--primaryText);
  }
  @media screen and (max-width: 1140px) {
    .month {
      padding: 1ch 0.5ch;
    }
    .long {
      display: none;
    }
    .short {
      display: inline;
    }
  }

  @media screen and (max-height: 1000px) and (min-width: 769px) {
    .long {
      display: none;
    }
    .short {
      display: inline;
    }
  }

  @media print {
    .show-months {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .show-months:not(.showInSidebar) {
      display: none;
    }

    .show-months.showInSidebar {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
    }
    .show-months.showInSidebar .year-container {
      grid-row: 4;
      grid-column: 1 / -1;
    }
    .show-months.showInSidebar .year-container .year-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      position: static;
      transform: none;
      pointer-events: all;
      width: unset;
      border-top: 1px solid black;
      padding-top: 0.5ch;
    }

    .show-months.showInSidebar .year-container .month.year {
      display: none;
    }

    .show-months.showInSidebar .year-container .year-list li {
      width: unset;
      transform: none;
      transition: none;
      opacity: 1;
    }
    .show-months.showInSidebar .year-container .year-list li :global(button) {
      padding: unset;
      border: 1px solid var(--primary);
      background-color: var(--primaryDark);
      padding: 1ch 0.5ch;
    }
    .show-months.showInSidebar .year-container .year-list li.selected :global(button) {
      border-color: var(--primaryDark);
      background-color: var(--primary);
      color: var(--primaryText);
    }
  }
  @media screen and (max-height: 1000px) and (min-width: 769px) {
    .show-months:not(.showInSidebar) {
      display: none;
    }

    .show-months.showInSidebar {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
    }
    .show-months.showInSidebar .year-container {
      grid-row: 4;
      grid-column: 1 / -1;
    }
    .show-months.showInSidebar .year-container .year-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      position: static;
      transform: none;
      pointer-events: all;
      width: unset;
      border-top: 1px solid black;
      padding-top: 0.5ch;
    }

    .show-months.showInSidebar .year-container .month.year {
      display: none;
    }

    .show-months.showInSidebar .year-container .year-list li {
      width: unset;
      transform: none;
      transition: none;
      opacity: 1;
    }
    .show-months.showInSidebar .year-container .year-list li :global(button) {
      padding: unset;
      border: 1px solid var(--primary);
      background-color: var(--primaryDark);
      padding: 1ch 0.5ch;
    }
    .show-months.showInSidebar .year-container .year-list li.selected :global(button) {
      border-color: var(--primaryDark);
      background-color: var(--primary);
      color: var(--primaryText);
    }
  }
</style>
