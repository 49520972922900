<script lang="ts">
  import CenteredFullPage from './CenteredFullPage.svelte';
  import Headline from './Headline.svelte';
  import Logo from './Logo.svelte';
</script>

<CenteredFullPage>
  <Logo colored large />
  <Headline>Menu<span>Planner</span></Headline>
  <p>
    Loading
    <span>.</span>
    <span style="--delay: 1;">.</span>
    <span style="--delay: 2;">.</span>
  </p>
  <div style="height: 64px" />
</CenteredFullPage>

<style lang="scss">
  p {
    color: white;
    span {
      --delay: 0;
      display: inline-block;
      animation: loading 500ms ease-in-out infinite alternate;
      animation-delay: calc(var(--delay) * 150ms);
    }
  }
  @keyframes loading {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }
</style>
