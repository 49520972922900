<script lang="ts">
  import { fly } from 'svelte/transition';

  export let monthAndYear: string;
  export let month: string;
  export let year: number;
</script>

<div class="title">
  <h1 class="grid">
    {#key monthAndYear}
      <span in:fly={{ x: -30, duration: 400 }} out:fly={{ x: 30, duration: 200 }}>
        {month}
        {year}
      </span>
    {/key}
  </h1>
</div>

<style>
  .title {
    display: grid;
    padding: 0;
    color: var(--primaryText);
    z-index: 1;
  }

  .grid > * {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
  }

  h1.grid {
    text-shadow: 0 2px 10px #00000066;
    display: grid;
    margin: 0;
    font-size: xx-large;
  }

  @media screen and (max-width: 768px) {
    h1.grid {
      font-size: 1.2rem;
    }
  }

  @media print {
    .title {
      padding: 0;
      padding-bottom: 0.5rem;
    }

    h1.grid {
      font-size: medium;
      color: black;
      text-shadow: none;
    }
  }
</style>
