<script lang="ts">
  import { user } from '../stores/user';
  import Button from './Button.svelte';
  import { doSignOut } from '../firebase';
  import { isSideBarOpen } from '../stores/dropDowns';
  import { toggleOpen } from '../util';
  import Logo from './Logo.svelte';
  import Headline from './Headline.svelte';
  import SelectMonth from './SelectMonth.svelte';
  import ShareBox from './ShareBox.svelte';
  import { getFunctions, httpsCallable } from 'firebase/functions';
  import SharingMessage from './SharingMessage.svelte';
  import ShareConfirm from './ShareConfirm.svelte';

  let avatar;
  export let reset;

  const functions = getFunctions();

  $: {
    if ($user?.name) {
      const [firstName, lastName] = $user.name.split(' ');
      const first = firstName.substring(0, 1);
      const last = lastName.substring(0, 1);

      avatar = `${first}${last}`;
    }
  }

  function doPrint() {
    try {
      // Print for Safari browser
      document.execCommand('print', false, null);
    } catch {
      {
        window.print();
      }
    }
    isSideBarOpen.set(false);
  }

  const confirm = httpsCallable(functions, 'newConfirmShare');

  async function handleConfirm() {
    console.log('clicked');

    const { data } = await confirm({ email: $user.email });
    console.log(data);
  }
</script>

<div class="wrapper" class:open={$isSideBarOpen}>
  <div class="brand">
    <div class="logo">
      <Logo colored />
    </div>
    <Headline size="small">Menu<span>Planner</span></Headline>
    <div class="close" on:click={toggleOpen}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
        ><path d="M0 0h24v24H0V0z" fill="none" /><path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
        /></svg
      >
    </div>
  </div>
  <div class="not-brand">
    <div class="user-name">
      {#if $user?.picture}
        <img src={$user.picture} alt="" />
      {:else}
        <div class="avatar">{avatar}</div>
      {/if}
      {#if $user?.name}
        <p>{$user?.name}</p>
      {/if}
    </div>
    <SelectMonth {reset} showInSidebar />
    {#if $user && !$user?.sharedEmail}
      <ShareBox />
    {/if}
    {#if $user}
      <ShareConfirm location="sidebar" />
    {/if}
    <Button size="medium" className="print" on:click={doPrint}>
      <div style="display: flex; gap: 1ch;justify-content: center;">
        Print
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#fff"
          ><path d="M0 0h24v24H0V0z" fill="none" /><path
            d="M19 8h-1V3H6v5H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zM8 5h8v3H8V5zm8 12v2H8v-4h8v2zm2-2v-2H6v2H4v-4c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v4h-2z"
          /><circle cx="18" cy="11.5" r="1" /></svg
        >
      </div>
    </Button>

    <Button size="small" on:click={doSignOut}>Logout</Button>
    <SharingMessage location="sidebar" />
  </div>
</div>

<style lang="scss">
  .close svg {
    fill: white;
  }

  .wrapper {
    padding: 0.5rem 1rem;
    background-color: var(--altColor);
    /* box-shadow: 0 0 10px #000000cc; */
    filter: drop-shadow(0 0 10px #000000cc);
    z-index: 200;
    position: relative;
    & :global(.print) {
      display: none;
    }
    & > * {
      display: flex;
      gap: 1ch;
    }

    @media screen and (max-height: 1000px) and (min-width: 769px) {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 300px;
      z-index: 2;
      flex-direction: column;
      transform: translateX(100%);
      align-items: flex-start;
      transition: transform 300ms ease-in-out;
      align-items: stretch;
      justify-content: flex-start;

      &.open {
        transform: translateX(0);
      }

      &:not(.open) {
        /* box-shadow: none; */
        filter: none;
      }

      & :global(.print) {
        display: block;
      }
    }

    @media screen and (max-width: 768px) {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 80%;
      z-index: 2;
      flex-direction: column;
      transform: translateX(100%);
      align-items: flex-start;
      transition: transform 300ms ease-in-out;
      align-items: stretch;
      justify-content: flex-start;

      &.open {
        transform: translateX(0);
      }

      &:not(.open) {
        /* box-shadow: none; */
        filter: none;
      }

      & :global(.print) {
        display: block;
      }
    }
    @media print {
      display: none;
    }
  }

  .close {
    display: none;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .user-name {
    gap: 1ch;
  }

  p {
    margin: 0;
    color: var(--primaryText);
  }

  .avatar {
    display: grid;
    place-content: center;
    padding: 0.5ch;
    background-color: var(--primary);
    color: var(--primaryText);
    font-weight: bolder;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
  }

  img {
    border-radius: 50%;
    overflow: hidden;
    width: 30px;
  }

  @media screen and (max-width: 768px) {
    .brand {
      margin-bottom: 2ch;
    }

    .not-brand {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .user-name {
      width: 100%;
      margin-bottom: auto;
    }

    .user-name p {
      margin-right: auto;
    }

    .wrapper :global(button) {
      width: 100% !important;
      height: 36px !important;
    }

    .close {
      margin-top: 5px;
      display: block;
    }

    .logo {
      display: none;
    }
  }
  @media screen and (max-height: 1000px) and (min-width: 769px) {
    .brand {
      margin-bottom: 2ch;
    }

    .not-brand {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .user-name {
      width: 100%;
      margin-bottom: auto;
    }

    .user-name p {
      margin-right: auto;
    }

    .wrapper :global(button) {
      width: 100% !important;
      height: 36px !important;
    }

    .close {
      margin-top: 5px;
      display: block;
    }

    .logo {
      display: none;
    }
  }
</style>
