<script lang="ts">
  import { user } from '../stores/user';
  import Button from './Button.svelte';
  import { getFunctions, httpsCallable } from 'firebase/functions';

  export let location: 'footer' | 'sidebar';

  const functions = getFunctions();

  const confirm = httpsCallable(functions, 'newConfirmShare');
  let disabled = false;

  async function handleConfirm() {
    disabled = true;
    const { data } = await confirm();
    if (data === 'done') {
      disabled = false;
    }
  }
</script>

{#if $user && $user.sharedEmail && !$user.verified}
  <div class={`confirm ${location}`}>
    <p><strong>{$user?.sharedEmail}</strong> wants to share a menu with you</p>
    <Button
      size="small"
      backgroundColor="white"
      textColor="black"
      {disabled}
      loading={disabled}
      on:click={handleConfirm}>Confirm</Button
    >
    <Button {disabled} size="small" backgroundColor="black" textColor="white"
      >Reject</Button
    >
  </div>
{/if}

<style>
  .confirm {
    display: flex;
    align-items: center;
    gap: 1ch;
    border: 1px solid white;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
  }

  p {
    margin: 0;
    color: var(--primaryText);
  }

  @media screen and (max-width: 768px) {
    .sidebar {
      display: none;
    }
    .footer {
      display: block;
      padding: 2rem;
      margin: 0 1rem;
      text-align: center;
      background-color: var(--altColor);
      box-shadow: 0 5px 10px #0000005a;
    }
    .footer p {
      margin-bottom: 1ch;
    }
  }
</style>
